:root {
    --clear-button-icon-pad: 2px;
    --clear-button-icon-width: 10px;

    --icon-color: #999;
}

.rdn {
  position: relative;
}

.rdn-control {
  position: relative;
  overflow: hidden;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 2px;
  box-sizing: border-box;
  color: #333;
  cursor: default;
  outline: none;
  padding: 8px 52px 8px 10px;
  transition: all 200ms ease;
}

.rdn-control:hover {
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
}

.rdn-control-icon {
  display: block;
  height: 0;
  margin-top: -6px;
  position: absolute;
  right: 10px;
  top: 14px;
}

.rdn-control-clear {
  position: absolute;
  top: 8px;
  right: 30px;
  font-size: 0;
  cursor: pointer;
}

.rdn-control-clear-button {
  border: none;
  padding: 0;
  margin: 0;
  text-decoration: none;
  background: transparent;    
  position:relative;
  width: calc(var(--clear-button-icon-width) * 2);
  height: calc(var(--clear-button-icon-width) * 2);
  cursor: pointer;
}
    
.rdn-control-clear-button-icon {
  position: absolute;
  left: 3px;
  top: 3px;

  width: var(--clear-button-icon-width);
  height: var(--clear-button-icon-width);
}

.rdn-control-clear-button-icon:before,
.rdn-control-clear-button-icon:after {
  position: absolute;
  left: calc(var(--clear-button-icon-width) / 2 * 1px);
  content: ' ';
  height: calc(var(--clear-button-icon-width) + 3px);
  width: 2px;
  background-color: var(--icon-color);
}

.rdn-control-clear-button-icon:before {
  transform: rotate(45deg);
}

.rdn-control-clear-button-icon:after {
  transform: rotate(-45deg);
}

.rdn-control-arrow-icon {
  border-color: var(--icon-color) transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: ' ';
  display: block;
  height: 0;
  margin-top: -ceil(2.5);
  position: absolute;
  right: 10px;
  top: 14px;
  width: 0;
}

.rdn-control-arrow-icon.is-open {
  border-color: transparent transparent var(--icon-color);
  border-width: 0 5px 5px;
}

.rdn-drop,
.rdn-selection {
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-top: -1px;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
}

.rdn-selection {
  position: relative;
  z-index: auto;
}

.rdn-drop-menu-group-title,
.rdn-selection-menu-group-title {
  padding: 8px 10px;
  color: rgba(51, 51, 51, 1);
  font-weight: bold;
  text-transform: capitalize;
}

.rdn-drop-menu-option,
.rdn-drop-menu-group-option,
.rdn-selection-menu-option,
.rdn-selection-menu-group-option {
  box-sizing: border-box;
  color: rgba(51, 51, 51, 0.8);
  cursor: pointer;
  display: block;
  padding: 8px 10px;
}

.rdn-drop-menu-option.is-disabled-option,
.rdn-drop-menu-group-option.is-disabled-option,
.rdn-selection-menu-option.is-disabled-option,
.rdn-selection-menu-group-option.is-disabled-option {
  cursor: default;
}

.rdn-drop-menu-option:last-child,
.rdn-drop-menu-group-option:last-child,
.rdn-selection-menu-option:last-child,
.rdn-selection-menu-group-option:last-child {
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}

.rdn-drop-menu-option:hover,
.rdn-drop-menu-group-option:hover,
.rdn-selection-menu-option:hover,
.rdn-selection-menu-group-option:hover {
  background-color: #f2f9fc;
  color: #333;
}

.rdn-drop-menu-option.is-disabled-option:hover,
.rdn-drop-menu-group-option.is-disabled-option:hover,
.rdn-selection-menu-option.is-disabled-option:hover,
.rdn-selection-menu-group-option.is-disabled-option:hover {
  background-color: #fff;
  color: rgba(51, 51, 51, 0.8);
}

.rdn-drop-menu-option.is-selected,
.rdn-drop-menu-group-option.is-selected,
.rdn-selection-menu-option.is-selected,
.rdn-selection-menu-group-option.is-selected {
  background-color: #f2f9fc;
  color: #333;
}

.rdn-drop-menu-noresults,
.rdn-selection-menu-noresults {
  box-sizing: border-box;
  color: #ccc;
  cursor: default;
  display: block;
  padding: 8px 10px;
}
